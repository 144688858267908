import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SelectCoupan from "../../../../component/SelectCoupan";
import {
  extendRideAction,
  getExtendPackageDetails,
} from "../../../../redux/actions/rentActions/bookingAction";
import { useState } from "react";
import { editBookingIcon, fullFace, halfFace } from "../../../../assets";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { FirebaseEvents } from "../../../../utills/firebaseEvents";

const ExtendRideTab = ({
  id,
  packageId,
  setModalFlag,
  branchId,
  cityId,
  vehicle_id,
  brand_id,
  ExcessKmsCharges,
  callOrderApi,
  extendHelmetCharges,
  model_id,
  helmetCatgoryId,
  odometerReading,
  moduleBranches,
  booking_id,
  isExtendedFromBookingList,
}) => {
  const { packageForExtend } = useSelector(
    (state: any) => state.bookingReducer
  );
  console.log(packageId, "packageId");
  const dispatch = useDispatch();
  const [extendObject, setExtendObject] = useState({
    packageName: "",
    price: 0,
    coupanCode: "",
    coupanId: null,
    discount: 0,
    helmetCharges: 0,
    extraChargesOfExcessKms: 0,
    revisedPrice: 0,
    helmetCatgoryId: null,
  });
  const [selectedPackage, setSelectedPackage] = useState<any>([]);
  useEffect(() => {
    if (packageForExtend?.length && packageId) {
      const currentPackage =
        packageForExtend.filter((_res) => _res.packageId == packageId) || [];
      setSelectedPackage(currentPackage);
    }
  }, [packageForExtend, packageId]);

  useEffect(() => {
    if (packageForExtend.length == 0 && isExtendedFromBookingList)
      dispatch(getExtendPackageDetails({ booking_id }));
  }, []);

  const [extendRideLoader, setExtendRideLoader] = useState<boolean>(false);

  useEffect(() => {
    setExtendObject({
      packageName:
        (selectedPackage[0] && selectedPackage[0]["packageName"]) || "",
      price: (selectedPackage[0] && Number(selectedPackage[0]["price"])) || 0,
      coupanCode: "",
      coupanId: null,
      discount: 0,
      helmetCharges: extendHelmetCharges || 0,
      extraChargesOfExcessKms: ExcessKmsCharges,
      helmetCatgoryId,
      revisedPrice:
        (selectedPackage[0] &&
          Number(selectedPackage[0]["price"]) +
            Number(ExcessKmsCharges) +
            Number(extendHelmetCharges)) ||
        0,
    });
  }, [packageId, ExcessKmsCharges, extendHelmetCharges, selectedPackage]);

  const handlePayment = () => {
    FirebaseEvents("ExtendRideRequestInitiation", { package: packageName });
    console.log("ExtendRideRequestInitiation", { package: packageName });
    setExtendRideLoader(true);
    const payload = {
      booking_id: id,
      package_id: Number(packageId),
      new_vehicle_id: vehicle_id,
      coupon_code: extendObject.coupanId,
      branchId: branchId,
      sourceId: brand_id,
      city_id: cityId,
      helmet_category_id: helmetCatgoryId,
      odometer_reading: odometerReading,
      request_from: "WEB",
    };
    dispatch(extendRideAction(payload, handleSuccess, setExtendRideLoader));
  };
  const {
    packageName,
    price,
    coupanCode,
    discount,
    helmetCharges,
    revisedPrice,
    extraChargesOfExcessKms,
  } = extendObject;

  const handleSuccess = (resp) => {
    if (resp) {
      let respObj = resp.Result;
      callOrderApi(respObj.amount, "4", respObj?.helpData, stopLoader);
    } else stopLoader();
  };

  const stopLoader = () => setExtendRideLoader(false);

  const handleCoupanApplied = (selectedCoupon, couponCodes) => {
    //on click of apply coupon
    let coupanCode = selectedCoupon?.code;
    if (!coupanCode) {
      toastr.error("Oops", "This coupon code does not apply on this vehicle.");
    } else {
      const index = couponCodes?.findIndex((data) => data.code == coupanCode);
      if (checkCouponPackage(selectedCoupon, couponCodes)) {
        //index == -1 , means code not availabe
        if (index != -1) {
          if (selectedCoupon?.coupon_code_cities?.length > 0) {
            const findCity = selectedCoupon?.coupon_code_cities?.findIndex(
              (data) => data.city_id == cityId
            );
            if (findCity != -1) {
              //to check date is valid or notx
              var start = moment(selectedCoupon.start_date).format(
                "DD/MM/YYYY"
              );
              var end = moment(selectedCoupon.end_date).format("DD/MM/YYYY");
              var dateCheck = moment().format("DD/MM/YYYY");

              var d1 = start.split("/");
              var d2 = end.split("/");
              var c = dateCheck.split("/");

              var from = new Date(
                parseInt(d1[2]),
                parseInt(d1[1]) - 1,
                parseInt(d1[0])
              ); // -1 because months are from 0 to 11
              var to = new Date(
                parseInt(d2[2]),
                parseInt(d2[1]) - 1,
                parseInt(d2[0])
              );
              var check = new Date(
                parseInt(c[2]),
                parseInt(c[1]) - 1,
                parseInt(c[0])
              );

              let addHelmet = helmetCharges;
              let delCharges = 0;

              if (check >= from && check <= to) {
                //now check code is in INR or percentage
                if (selectedCoupon.apply_on_package == 1) {
                  const type = selectedCoupon.unit;
                  if (type == 1) {
                    if (price < selectedCoupon.discount) {
                      toastr.error(
                        "Oops",
                        "This coupon code cannot be applied as coupon price exceeds the package price."
                      );
                    } else {
                      let discount =
                        selectedCoupon.free_additonal_helmet == 1
                          ? Math.round(selectedCoupon.discount) + helmetCharges
                          : Math.round(selectedCoupon.discount);
                      setExtendObject((prev) => ({
                        ...prev,
                        revisedPrice:
                          prev.revisedPrice + prev.discount - discount,
                        discount: discount,
                        coupanId: selectedCoupon.id,
                        coupanCode: selectedCoupon.code,
                      }));
                    }
                  } else {
                    const surgeCharges = selectedPackage[0]?.surge || 0;
                    const per =
                      ((price - surgeCharges) / 100) * selectedCoupon.discount;

                    if (price - surgeCharges < per) {
                      toastr.error(
                        "Oops",
                        "This coupon code cannot be applied as coupon price exceeds the package price."
                      );
                    } else {
                      // to check upto here if it is not maximum
                      if (per <= selectedCoupon.upto) {
                        let discount =
                          selectedCoupon.free_additonal_helmet == 1
                            ? Math.round(per) + helmetCharges
                            : Math.round(per);
                        setExtendObject((prev) => {
                          return {
                            ...prev,
                            revisedPrice:
                              prev.revisedPrice + prev.discount - discount,
                            discount: discount,
                            coupanId: selectedCoupon.id,
                            coupanCode: selectedCoupon.code,
                          };
                        });
                      } else {
                        let discount =
                          selectedCoupon.free_additonal_helmet == 1
                            ? Math.round(selectedCoupon.upto) + helmetCharges
                            : Math.round(selectedCoupon.upto);
                        setExtendObject((prev) => ({
                          ...prev,
                          revisedPrice:
                            prev.revisedPrice + prev.discount - discount,
                          discount: discount,
                          coupanCode: selectedCoupon.code,
                          coupanId: selectedCoupon.id,
                        }));
                        toastr.error(
                          "Oops",
                          "You can avail max discount upto Rs." +
                            selectedCoupon.upto
                        );
                      }
                    }
                  }
                }
                if (
                  selectedCoupon.free_home_delivery == 1 &&
                  selectedCoupon.free_additonal_helmet == 0 &&
                  selectedCoupon.apply_on_package == 0
                ) {
                  setExtendObject((prev) => ({
                    ...prev,
                    coupanCode: "",
                    coupanId: null,
                    revisedPrice: prev.revisedPrice + prev.discount,
                    discount: 0,
                  }));
                  toastr.error(
                    "Oops",
                    "This coupon is not applicable on extend ride !! "
                  );
                }
              } else {
                setExtendObject((prev) => ({
                  ...prev,
                  coupanCode: "",
                  coupanId: null,
                  revisedPrice: prev.revisedPrice + prev.discount,
                  discount: 0,
                }));
                toastr.error("Oops", "This coupon code has expired !! ");
              }
            } else {
              setExtendObject((prev) => ({
                ...prev,
                coupanCode: "",
                coupanId: null,
                revisedPrice: prev.revisedPrice + prev.discount,
                discount: 0,
              }));
              toastr.error(
                "Oops",
                "This coupon code is not available in your city. "
              );
            }
          } else {
            //in your city not available this code
            setExtendObject((prev) => ({
              ...prev,
              coupanCode: "",
              coupanId: null,
              revisedPrice: prev.revisedPrice + prev.discount,
              discount: 0,
            }));
            toastr.error(
              "Oops",
              "This coupon code is not available in your city. "
            );
          }
        } else {
          toastr.error("Oops", "No coupon code available.");
          setExtendObject((prev) => ({
            ...prev,
            coupanCode: "",
            coupanId: null,
            revisedPrice: prev.revisedPrice + prev.discount,
            discount: 0,
          }));
        }
      } else {
        setExtendObject((prev) => ({
          ...prev,
          coupon: "",
          coupanCode: selectedCoupon.code,
          coupanId: selectedCoupon.id,
          revisedPrice: price + helmetCharges + prev.discount,
        }));
      }
    }
  };

  const checkCouponPackage = (selectedCoupon, couponCodes) => {
    const index = couponCodes?.findIndex((data) => data.code == coupanCode);
    if (selectedCoupon?.coupon_code_vehicle_models?.length > 0) {
      const findPackage =
        selectedCoupon?.coupon_code_vehicle_models[0]?.coupon_code_packages?.findIndex(
          (data) => data.package_id == packageId
        );
      console.log(findPackage);
      if (findPackage == -1) {
        toastr.error(
          "Error",
          "This Coupon code is not applicable on selected package"
        );
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const handleCoupnRemoved = () => {
    setExtendObject((pre) => ({
      ...pre,
      revisedPrice: pre.revisedPrice + pre.discount,
      coupanCode: "",
      coupanId: null,
      discount: 0,
    }));
  };

  return (
    <div
      id="collapseSix"
      className="accordion-collapse collapse"
      aria-labelledby="headingSix"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <div className="extend-Price-Details">
          <div className="table-responsive">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td>Selected Package </td>
                  <td>
                    <strong>
                      {" "}
                      {packageName} ₹{Math.round(price)}/-
                    </strong>
                    <span
                      className="cp"
                      onClick={() =>
                        setModalFlag((prev) => ({
                          ...prev,
                          extendRide: true,
                        }))
                      }
                    >
                      <img src={editBookingIcon} alt=" edit" className="ms-1" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td> Excess Kms Charges </td>
                  <td> ₹ {Math.round(extraChargesOfExcessKms)}/- </td>
                </tr>
                {/* <tr>
                  <td>
                    <div className="helmate-wrap">
                      <label className="form-check-label">
                        {" "}
                        Rent Additional Helmet{" "}
                      </label>
                      <div className="d-flex rented-helmet">
                        <div className="p-2">
                          <div className="card text-center selectedHelmet">
                            <img
                              src={halfFace}
                              className="card-img-top"
                              alt="Half Face Helmet"
                            />
                            <div className="card-body">
                              <h6 className="card-title">Half Face</h6>
                              <h6>50/-</h6>
                            </div>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="card text-center">
                            <img
                              src={fullFace}
                              className="card-img-top"
                              alt="Full Face Helmet"
                            />
                            <div className="card-body">
                              <h6 className="card-title">Full Face</h6>
                              <h6>50/-</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr> */}
                {moduleBranches?.CouponCode && (
                  <tr>
                    <td>Apply Coupon</td>
                    <td>
                      <button
                        className="extened-coupon-btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#coupon-selected"
                        aria-controls="coupon-selected"
                      >
                        <span className="cpn-selected">
                          {" "}
                          {coupanCode || "Select Coupon"}{" "}
                        </span>
                        {/* <span className="cursor">
                        <img src={editBookingIcon} alt="" />
                      </span> */}
                      </button>
                      {coupanCode && (
                        <button
                          style={{ background: "none", border: "none" }}
                          className="p-2"
                          onClick={handleCoupnRemoved}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      )}
                      <SelectCoupan
                        cityId={cityId}
                        model_id={model_id}
                        handleCouponApplied={handleCoupanApplied}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Helmet Charge </td>
                  <td> ₹ {Math.round(helmetCharges)}/- </td>
                </tr>
                <tr>
                  <td> Discount </td>
                  <td>- ₹ {Math.round(discount)}/- </td>
                </tr>
                <tr>
                  <td>Revised Price</td>
                  <td>₹ {Math.round(revisedPrice)}/- </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="text-center">
                      <button
                        className="freedo-primary-button mt-2 rounded-pill text-dark"
                        onClick={handlePayment}
                      >
                        {extendRideLoader ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <>
                            Pay Now{" "}
                            {
                              <b className="ms-1">
                                ₹{Math.round(revisedPrice)}
                              </b>
                            }
                          </>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendRideTab;
