import moment from "moment";
import { infinite, prodImage, zero } from "../../assets";
import en from "../../locales/en.json";
const CheckoutProductDetail = (props: any) => {
  const { checkout } = en;
  const bikeDetail = props.productData;
  const rentDetail = props.rentData;

  return (
    <div className="product-details row">
      <div className=" col-10 col-sm-10 col-md-11">
        <img src={prodImage} className="prod-image" />
        <span className="product-detail">{checkout.productDetails}</span>
      </div>

      <div className="row">
        <div className="col-md-8 col-lg-7">
          <span className="hero smallest-text">{bikeDetail?.brandName}</span>
          <span className="head">{bikeDetail?.modelName}</span>
          <p className="rent-det">{checkout.rentDetails}</p>
          <div className="fee-wrapper">
            {bikeDetail.isZeroDeposit && (
              <div className="zero mt-1">
                <img src={zero} alt="Zero Deposit" />
                {checkout.zeroDeposit}
              </div>
            )}
            {bikeDetail.isUnlimitedKM && (
              <div className="unlimited mt-1">
                <img src={infinite} alt="Unlimited Kilometeres" />{" "}
                {checkout.unlimitedKm}
              </div>
            )}
          </div>
          {rentDetail && (
            <div className="date-progress">
              <div className="d-flex align-items-center location smallest-text">
                {" "}
                <i className="fas fa-location-dot me-1"></i>
                {rentDetail.city.city_name}
              </div>

              <div className="progressbar-wrap">
                <div className="smallest-text freedo-txt">
                  {moment(rentDetail.date).format("DD-MM-YYYY")}
                </div>
                <div className="progressbar">
                  <span className="active"></span>
                  <span className="next"></span>
                </div>
                <div className="ml-0 smallest-text">
                  {moment(rentDetail.endDate).format("DD-MM-YYYY")}
                </div>
              </div>

              <div className="day-one smallest-text">
                {rentDetail.package.package_name}
              </div>
            </div>
          )}
        </div>
        <div className="col-md-4 col-lg-5">
          <img
            src={bikeDetail.vehicleImagePath + bikeDetail.imageName}
            className="bike-right"
          />
        </div>
      </div>
    </div>
  );
};

export default CheckoutProductDetail;
