import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import {
  greenLocationMark,
  listingLocationIcon,
  orangeLocationMark,
  redLocationMark,
} from "../../assets";
import { commmaSeparator } from "../../utills/helper";
import { useEffect, useRef, useState } from "react";
const containerStyle = {
  width: "100%",
  height: "48vh",
};
export const GoogleMapComponent = ({
  selectdMarkerLocation,
  branchDetails = [],
  setBranchName,
  setSelectedCoupon,
  setAdditionalHelmet,
  setAdditionalHelmetData,
}: any) => {
  // State for the selected warehouse
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [zoom, setZoom] = useState<number>(10);
  const mapCenter = () => {
    if (branchDetails.length) {
      if (selectedIndex) {
        return {
          lat: branchDetails[selectedIndex].latitude,
          lng: branchDetails[selectedIndex].longitude,
        };
      }
      // Default center when no marker is selected
      return {
        lat: branchDetails[0].latitude,
        lng: branchDetails[0].longitude,
      };
    } else {
      return { lat: 0.0, lng: 0.0 };
    }
  };

  const handleMaplick = (item: any, index: number) => {
    setSelectedWarehouse(item);
    selectdMarkerLocation(item);
    setBranchName(item);
    setSelectedCoupon(null);
    setAdditionalHelmet(false);
    setAdditionalHelmetData(false);
    setSelectedIndex(index);
    setZoom(12);
  };
  const handleListingClick = (item: any, index: number) => {
    setSelectedWarehouse(item);
    selectdMarkerLocation(item);
    setBranchName(item);
    setSelectedCoupon(null);
    setAdditionalHelmet(false);
    setAdditionalHelmetData(false);
    setSelectedIndex(index);
    setZoom(12);
  };

  const getFullAddress = (addr: any) => {
    return `${addr?.address1}, ${addr?.address2 ? addr?.address2 : ""}, ${
      addr?.landmark ? addr?.landmark : ""
    }, ${addr?.zip}`;
  };
  // Initially select first branch
  useEffect(() => {
    if (branchDetails?.length === 1) {
      setSelectedWarehouse(branchDetails[0]);
      selectdMarkerLocation(branchDetails[0]);
    }
  }, [branchDetails]);
  // Reset zoom to show all markers if no selection is made
  useEffect(() => {
    if (selectedIndex === null) {
      setZoom(10); // Reset to zoom out when no marker is selected
    }
  }, [selectedIndex]);
  return (
    <div className="row mt-2">
      <div className="map col-md-8">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter()}
          zoom={zoom}
          clickableIcons={false}
        >
          {branchDetails.map((item: any, index: number) => (
            <Marker
              key={index}
              position={{
                lat: item.latitude,
                lng: item.longitude,
              }}
            >
              <InfoWindow
                position={{
                  lat: item.latitude,
                  lng: item.longitude,
                }}
              >
                <div
                  className="mapWarehouseInfo"
                  onClick={() => handleMaplick(item, index)}
                >
                  <div
                    className={`mapWarehouseInfoPricing ${
                      selectedWarehouse?.branchName === item.branchName
                        ? "selected"
                        : ""
                    }`}
                  >
                    ₹ {commmaSeparator(Math.round(item.price))}
                  </div>
                  <div className="mapWarehouseName">
                    <p>
                      {item?.hubMapName ? item.hubMapName : item.branchName}
                    </p>
                  </div>
                </div>
              </InfoWindow>
            </Marker>
          ))}
        </GoogleMap>
      </div>
      <div className="map col-12 col-md-4">
        <CardListing
          branchDetails={branchDetails}
          selectedWarehouse={selectedWarehouse}
          handleListingClick={handleListingClick}
          getFullAddress={getFullAddress}
          selectedIndex={selectedIndex}
        />
      </div>
    </div>
  );
};
const CardListing = ({
  branchDetails,
  handleListingClick,
  selectedWarehouse,
  getFullAddress,
  selectedIndex = null,
}: any) => {
  const addressRefs = useRef<any>([]);
  // Scroll to the selected address
  useEffect(() => {
    if (selectedIndex !== null && addressRefs.current[selectedIndex]) {
      addressRefs.current[selectedIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedIndex]);
  return (
    <div className="card-listing">
      <span className="listing-heading">Click to select hub</span>
      <div className="card-scroll-listing">
        {branchDetails.map((item: any, index: number) => (
          <div
            className={`card-warehouse-listing ${
              selectedWarehouse?.branchName === item.branchName
                ? "selected"
                : ""
            }`}
            key={index}
            onClick={() => handleListingClick(item, index)}
            ref={(el) => (addressRefs.current[index] = el)}
          >
            <img src={listingLocationIcon} style={{ marginTop: "4px" }} />
            <div className="card-warehouse-info">
              <div className="card-warehouse-infoBox">
                <span
                  className="card-warehouse-heading"
                  data-toggle="tooltip"
                  title={item.branchName}
                >
                  {item.branchName}
                </span>
                <p>₹ {commmaSeparator(Math.round(item.price))}</p>
              </div>
              <div className="card-warehouse-location">
                <p>{getFullAddress(item?.address)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
