import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  excesshourly,
  excesskms,
  fullFace,
  halfFace,
  helmetgif,
  tag,
  totalkms,
} from "../../assets";
import SelectCoupan from "../../component/SelectCoupan";
import { LocalStorage } from "../../constant/enum";
import en from "../../locales/en.json";
import { commmaSeparator, getLocalStorageData } from "../../utills/helper";
import BillingPrice from "./BillingPrice";
import { Link } from "react-router-dom";
import { Routes } from "../../constant/routeContants";

const BillingDetails = (props: any) => {
  const {
    selectedBranchData,
    model_id,
    packageId,
    homeDeliverCharges,
    isCOD,
    handleUpdateData,
    handlePayClick,
    payNowLoader,
    nevigate,
    addressNotDeliverable,
    isHomeDelivery,
    branchData,
    productData,
    setSelectedCoupon,
    selectedCouponData,
    setAdditionalHelmet,
    additionalHelmet,
    setAdditionalHelmetData,
    additionalHelmetData,
  } = props;

  const { checkout } = en;

  const cityData = getLocalStorageData(LocalStorage.userCity);
  const getTotalPrice = () => {
    if (selectedBranchData) {
      let totalPrice =
        selectedBranchData?.price +
        (additionalHelmetData ? additionalHelmetData.helmetCharges : 0) -
        calculateCouponCharge() +
        (homeDeliverCharges?.home_delivery_charge || 0);
      let finalTotl =
        Number(totalPrice) +
        Number(
          selectedBranchData?.configModuleDetails?.SecurityAmount &&
            selectedBranchData?.securityAmt
            ? selectedBranchData?.securityAmt
            : 0
        );
      return commmaSeparator(Math.round(finalTotl));
    } else {
      return 0;
    }
  };
  const handleCoupnSelect = (item) => {
    //on click of apply coupon
    let selectedCoupon = { ...item };
    let coupanCode = selectedCoupon.code;
    if (coupanCode == "") {
      toastr.error("Oops", "Please enter coupon code.");
    } else {
      if (checkCouponPackage(selectedCoupon)) {
        //index == -1 , means code not availabe
        if (item) {
          if (selectedCoupon?.coupon_code_cities?.length > 0) {
            const findCity = selectedCoupon?.coupon_code_cities?.findIndex(
              (data) => data.city_id == cityData?.id
            );
            if (findCity != -1) {
              //to check date is valid or notx
              var start = moment(selectedCoupon.start_date).format(
                "DD/MM/YYYY"
              );
              var end = moment(selectedCoupon.end_date).format("DD/MM/YYYY");
              var dateCheck = moment().format("DD/MM/YYYY");

              var d1 = start.split("/");
              var d2 = end.split("/");
              var c = dateCheck.split("/");

              var from = new Date(
                parseInt(d1[2]),
                parseInt(d1[1]) - 1,
                parseInt(d1[0])
              ); // -1 because months are from 0 to 11
              var to = new Date(
                parseInt(d2[2]),
                parseInt(d2[1]) - 1,
                parseInt(d2[0])
              );
              var check = new Date(
                parseInt(c[2]),
                parseInt(c[1]) - 1,
                parseInt(c[0])
              );

              if (check >= from && check <= to) {
                //now check code is in INR or percentage
                if (selectedCoupon.apply_on_package == 1) {
                  const type = selectedCoupon.unit;
                  if (type == 1) {
                    if (selectedBranchData?.price < selectedCoupon.discount) {
                      toastr.error(
                        "Oops",
                        "This coupon code cannot be applied as coupon price exceeds the package price."
                      );
                      setSelectedCoupon(null);
                      handleUpdateData("selectedCoupon", null);
                    } else {
                      setSelectedCoupon(selectedCoupon);
                      handleUpdateData("selectedCoupon", selectedCoupon);
                    }
                  } else {
                    const per =
                      ((selectedBranchData?.price -
                        (selectedBranchData?.surge || 0)) /
                        100) *
                      selectedCoupon.discount;

                    if (
                      selectedBranchData?.price -
                        (selectedBranchData?.surge || 0) <
                      per
                    ) {
                      toastr.error(
                        "Oops",
                        "This coupon code cannot be applied as coupon price exceeds the package price."
                      );
                      setSelectedCoupon(null);
                      handleUpdateData("selectedCoupon", null);
                    } else {
                      // to check upto here if it is not maximum
                      if (per <= selectedCoupon.upto) {
                        selectedCoupon.discount = parseFloat(String(per));
                        setSelectedCoupon(selectedCoupon);
                        handleUpdateData("selectedCoupon", selectedCoupon);
                      } else {
                        selectedCoupon.discount = selectedCoupon.upto;
                        setSelectedCoupon(selectedCoupon);
                        handleUpdateData("selectedCoupon", selectedCoupon);
                        toastr.error(
                          "Oops",
                          "You can avail max discount upto Rs." +
                            selectedCoupon.upto
                        );
                      }
                    }
                  }
                }
                if (selectedCoupon.free_additonal_helmet == 1) {
                  setSelectedCoupon(selectedCoupon);
                  handleUpdateData("selectedCoupon", selectedCoupon);
                }
                if (
                  selectedCoupon.free_home_delivery == 1 &&
                  selectedCoupon.free_additonal_helmet == 0 &&
                  selectedCoupon.apply_on_package == 0
                ) {
                  setSelectedCoupon(null);
                  handleUpdateData("selectedCoupon", null);
                  toastr.error(
                    "Oops",
                    "This coupon is not applicable on extend ride !! "
                  );
                }
              } else {
                setSelectedCoupon(null);
                handleUpdateData("selectedCoupon", null);
                toastr.error("Oops", "This coupon code has expired !! ");
              }
            } else {
              setSelectedCoupon(null);
              handleUpdateData("selectedCoupon", null);
              toastr.error(
                "Oops",
                "This coupon code is not available in your city. "
              );
            }
          } else {
            //in your city not available this code
            toastr.error(
              "Oops",
              "This coupon code is not available in your city. "
            );
          }
        } else {
          toastr.error("Oops", "No coupon code available.");
          setSelectedCoupon(null);
          handleUpdateData("selectedCoupon", null);
        }
      } else {
        setSelectedCoupon(null);
        handleUpdateData("selectedCoupon", null);
      }
    }
  };

  const checkCouponPackage = (selectedCoupon) => {
    if (selectedCoupon?.coupon_code_vehicle_models?.length > 0) {
      const findPackage =
        selectedCoupon?.coupon_code_vehicle_models[0]?.coupon_code_packages?.findIndex(
          (data) => data.package_id == packageId
        );
      console.log(findPackage);
      if (findPackage == -1) {
        toastr.error(
          "Error",
          "This Coupon code is not applicable on selected package"
        );
        return false;
      } else {
        return true;
      }
    }
    return true;
  };
  const handleCoupnRemoved = () => {
    setSelectedCoupon(null);
    handleUpdateData("selectedCoupon", null);
  };
  const calculateCouponCharge = () => {
    return selectedCouponData
      ? Math.round(selectedCouponData.discount) +
          (selectedCouponData.free_additonal_helmet == 1 && additionalHelmetData
            ? additionalHelmetData?.helmetCharges
            : 0) +
          (selectedCouponData.free_home_delivery == 1 &&
          homeDeliverCharges?.home_delivery_charge
            ? homeDeliverCharges?.home_delivery_charge
            : 0)
      : 0;
  };

  const getPriceDetailData = () => {
    let totlCost = getTotalPrice();
    return {
      packageCharge: selectedBranchData?.price || 0,
      discount: calculateCouponCharge(),
      additionalHelmet: additionalHelmetData
        ? additionalHelmetData?.helmetCharges
        : 0,
      pickUpDeliveryCharges: homeDeliverCharges?.home_delivery_charge || 0,
      cgst: 0,
      sgst: 0,
      totalAmount: totlCost,
      securityAmt:
        selectedBranchData?.configModuleDetails?.SecurityAmount &&
        selectedBranchData?.securityAmt
          ? selectedBranchData?.securityAmt
          : 0,
    };
  };
  const additionHelmetSelection = () => {
    if (additionalHelmet) {
      setAdditionalHelmet(false);
      setAdditionalHelmetData(null);
      handleUpdateData("helmetData", null);
    } else {
      setAdditionalHelmet(true);
      setAdditionalHelmetData(selectedBranchData?.helmetConfigs[0]);
      handleUpdateData("helmetData", selectedBranchData?.helmetConfigs[0]);
    }
  };
  const additionHelmetChange = (item: any) => {
    setAdditionalHelmetData(item);
    handleUpdateData("helmetData", item);
  };

  return (
    <div className="col-12 col-md-5 coupon-right">
      {branchData && (
        <div className="add-details-wrapper">
          <p className="add-det">{checkout.additionaDetails}</p>

          <div className="details-wrapper">
            {!productData.isUnlimitedKM && (
              <>
                <div className="total-km">
                  <span>
                    <img src={totalkms} alt="Total Kilometers" />
                  </span>
                  <div className="additional-helmets-info">
                    <span>{checkout.totalKms}</span>

                    <span>{`${commmaSeparator(branchData.totalKm)} km`}</span>
                  </div>
                </div>
              </>
            )}

            <div className="excess-charge">
              <span>
                <img src={excesskms} alt="Excess Kilometeres" />
              </span>
              <div className="additional-helmets-info">
                <span>{checkout.excessKmChange}</span>

                <span>{`${branchData.excessPerKmCharge || 0}/km`}</span>
              </div>
            </div>

            <div className="excess-hour-charge">
              <span>
                <img src={excesshourly} alt="Excess Hourly Charges" />
              </span>
              <div className="additional-helmets-info">
                <span> {checkout.excessHrCharge} </span>

                <span>{`${branchData.excessPerHourCharge || 0}/hr`}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="first-row">
        <div className="coupon-details-wrapper">
          {selectedBranchData?.configModuleDetails?.CouponCode && (
            <>
              <h6>Coupons</h6>
              <div className="apply-coupon">
                {" "}
                <div className="apply-head">
                  {" "}
                  <img src={tag} />{" "}
                  <span className="checkout-coupon">
                    <span>
                      {selectedCouponData
                        ? selectedCouponData.code.toUpperCase()
                        : checkout.applyCoupon}
                    </span>
                    {selectedCouponData && (
                      <button
                        style={{ background: "none", border: "none" }}
                        className="p-2"
                        onClick={handleCoupnRemoved}
                      >
                        <i className="fa-solid fa-xmark mt-1"></i>
                      </button>
                    )}
                  </span>
                </div>
                <SelectCoupan
                  cityId={cityData?.id}
                  model_id={model_id}
                  handleCouponApplied={handleCoupnSelect}
                />
                <button
                  className="btn btn-sm btn-primary mb-2"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#coupon-selected"
                  aria-controls="coupon-selected"
                >
                  {" "}
                  {checkout.applyNow}
                </button>
              </div>
            </>
          )}
          <div className="helmet-wrap">
            <img
              src={helmetgif}
              alt="Complimentary Image"
              className="img-responsive float-end"
              width={40}
            />
            <h6>Freedo Helmet </h6>
            <p>One helmet is complimentary.</p>
          </div>

          <div className="helmate-wrap">
            <form>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rentAdditionalHelmet"
                  checked={additionalHelmet}
                  onChange={(e) => additionHelmetSelection()}
                />
                <label className="form-check-label">
                  {" "}
                  Rent Additional Helmet{" "}
                </label>
              </div>
              {additionalHelmet && (
                <div className="d-flex rented-helmet">
                  {selectedBranchData?.helmetConfigs.map(
                    (e: any, ind: number) => {
                      return (
                        <div
                          key={ind}
                          className="p-2 cp"
                          onClick={() => additionHelmetChange(e)}
                        >
                          <div
                            className={`card text-center ${
                              additionalHelmetData &&
                              additionalHelmetData?.helmetCategoryMaster?.id ===
                                e?.helmetCategoryMaster?.id
                                ? "selectedHelmet"
                                : ""
                            }`}
                          >
                            <img
                              src={
                                e.categoryName === "Full Face"
                                  ? fullFace
                                  : halfFace
                              }
                              className="card-img-top"
                              alt="Half Face Helmet"
                            />
                            <div className="card-body">
                              <h6 className="card-title">
                                {e?.helmetCategoryMaster?.categoryName}
                              </h6>
                              <h6>{`${e.helmetCharges}/-`}</h6>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {selectedBranchData?.configModuleDetails?.COD && (
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rentAdditionalHelmet"
                    checked={isCOD}
                    onChange={(e) => handleUpdateData("isCOD", !isCOD)}
                  />
                  <label className="form-check-label"> COD </label>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="coupon-details-wrapper pt-0">
        <button
          className="pay-now freedo-primary-button rounded-pill"
          onClick={() => handlePayClick()}
          disabled={
            payNowLoader || (isHomeDelivery && addressNotDeliverable?.Error)
          }
        >
          {payNowLoader ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              {isCOD ? checkout.confirmBooking : checkout.payNow}{" "}
              {!isCOD && <span>{`₹ ${getTotalPrice()}`}</span>}
            </>
          )}
        </button>
        <p className="pay-now-term-and-condition">
          By clicking on pay now you are accepting the{" "}
          <Link to={Routes.TERMS_CONDITIONS} title="term & condition">
            Terms & Conditions{" "}
          </Link>
          of Freedo
        </p>
        <BillingPrice
          classNamename="table-striped"
          priceDetail={getPriceDetailData()}
        />
      </div>
      <button
        type="button"
        id="EmrgNoCheck"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        style={{ display: "none" }}
      />
      <div
        className="modal fade"
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h5>Please add emergency contact before booking</h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={nevigate}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Go to Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
