import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtendPackageDetails,
  odometerReadingAction,
  setExtendRideState,
} from "../../../redux/actions/rentActions/bookingAction";
import { fullFace, halfFace } from "../../../assets";
import { useNavigate } from "react-router-dom";
import {
  commmaSeparator,
  getEncryptedText,
  restrictNumber,
} from "../../../utills/helper";
import { toastr } from "react-redux-toastr";
const ExtendRideModal = ({
  onHide,
  bookingDetailsById,
  packageDetail,
  packageMaster,
  cityId,
  setExtendState,
  handleExtendTab,
  from,
}: any) => {
  const {
    booking_id,
    bike_name,
    rental_days,
    status,
    id,
    additional_helmet,
    total_penalty_charge,
  } = bookingDetailsById;
  const { packageForExtend, extendRideState } = useSelector(
    (state: any) => state.bookingReducer
  );
  const {
    packageId,
    odometerReading,
    additionalHelmet,
    additionalHelmetData,
    selectedPackage,
  } = extendRideState;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      additionalHelmet &&
      !additionalHelmetData &&
      selectedPackage?.helmetConfiguration?.length
    ) {
      dispatch(
        setExtendRideState({
          ...extendRideState,
          additionalHelmetData: selectedPackage?.helmetConfiguration[0],
        })
      );
    }
  }, [additionalHelmet]);

  const handleExtend = (e) => {
    e.preventDefault();
    if (additionalHelmet && !additionalHelmetData) {
      toastr.info("Please select helemt first!", "");
      return;
    }
    let payload = {
      booking_id: id,
      odometer_reading: odometerReading,
      odometer_reading_checkbox: true,
    };
    dispatch(odometerReadingAction(payload, handleSuccess));
  };
  const handleSuccess = (data) => {
    if (handleExtendTab) handleExtendTab();
    onHide((prev) => ({
      ...prev,
      extendTab: true,
      extendRide: false,
    }));
    if (from === "currentBooking") {
      let object = {
        bookingId: id,
        packageId: packageId,
        param_extraChargesOfExcessKms: data?.Result?.amount || 0,
        param_extendHelmetCharges:
          additionalHelmetData?.hemletCharge ||
          (additional_helmet ? selectedPackage?.helmetCharges : 0),
        param_helmetCatgoryId:
          additionalHelmetData?.helmetCatgoryId ||
          (additional_helmet ? selectedPackage?.helmetcategoryId : null),
        param_odometerReading: odometerReading,
        isExtendedFromBookingList: true,
      };
      setExtendRideState({
        ...extendRideState,
        packageId: "",
        odometerReading: 0,
        additionalHelmet: false,
        additionalHelmetData: null,
        selectedPackage: {},
      });
      const encrypted = getEncryptedText(JSON.stringify(object));
      navigate(`/booking-details/${encrypted}`);
    } else {
      setExtendState((prev) => ({
        ...prev,
        extendPackageId: packageId,
        extraChargesOfExcessKms: data?.Result?.amount || 0,
        extendHelmetCharges:
          additionalHelmetData?.hemletCharge ||
          (additional_helmet ? selectedPackage?.helmetCharges : 0),
        helmetCatgoryId:
          additionalHelmetData?.helmetCatgoryId ||
          (additional_helmet ? selectedPackage?.helmetcategoryId : null),
        odometerReading,
      }));
    }
  };
  useEffect(() => {
    dispatch(getExtendPackageDetails({ booking_id }));
  }, []);

  useEffect(() => {
    if (packageId && packageForExtend) {
      const plan = packageForExtend.filter(
        (item) => item.packageId == packageId
      )[0];
      dispatch(
        setExtendRideState({
          ...extendRideState,
          selectedPackage: { ...plan },
          /* additionalHelmet: false,
          additionalHelmetData: null, */
        })
      );
    } else if (!packageId) {
      dispatch(
        setExtendRideState({
          ...extendRideState,
          odometerReading: 0,
          additionalHelmet: false,
          additionalHelmetData: null,
        })
      );
    }
  }, [packageId]);

  const handlePackage = (value) => {
    dispatch(setExtendRideState({ ...extendRideState, packageId: value }));
  };

  const HandleOdometerReading = (e) => {
    let value = e.target.value
      ? Number(restrictNumber(e.target.value))
      : e.target.value;
    value = value.toString().replace(/^0+/, "");
    dispatch(
      setExtendRideState({ ...extendRideState, odometerReading: value })
    );
  };

  const additionHelmetChange = (data: any) => {
    dispatch(
      setExtendRideState({ ...extendRideState, additionalHelmetData: data })
    );
  };
  const additionHelmetSelection = () => {
    if (additionalHelmet) {
      dispatch(
        setExtendRideState({
          ...extendRideState,
          additionalHelmet: false,
          additionalHelmetData: null,
        })
      );
    } else {
      dispatch(
        setExtendRideState({ ...extendRideState, additionalHelmet: true })
      );
    }
  };
  return (
    <div
      className="modal extend-ride-wrapper fade backdrop show"
      id="exampleModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header custom-modal">
            <h5>Extend Ride</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => onHide((prev) => ({ ...prev, extendRide: false }))}
            ></button>
          </div>
          <div className="modal-body text-start">
            <div className="extent-ride-body-wrap">
              <div className="extent-ride-details">
                <p>
                  Booking ID: <span className="idbook">{booking_id}</span>
                </p>
              </div>
              <div className="extent-ride-details ">
                <p>
                  Vehicle Name: <span>{bike_name}</span>
                </p>
              </div>
              <div className="extent-ride-details">
                <p>
                  {" "}
                  Previous Package: <span>{rental_days} Rental</span>
                </p>
              </div>
              <div className="extent-ride-details">
                <p>
                  Status: <span className="status-ongoing">{status}</span>
                </p>
              </div>
            </div>
            <div className="extend-ride-form">
              <form className="row align-items-center">
                <h6>Package & Odometer Reading</h6>
                <span className="text-muted mb-2 text-sm">
                  Please select new package and enter odometer reading to extend
                  your ride.
                </span>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <label htmlFor="extendRidePackage" className="form-label">
                    Select Package
                  </label>
                  <select
                    id="inputState"
                    className="form-select form-select-sm"
                    onChange={(e) => handlePackage(e.target.value)}
                    name="extendRidePackage"
                    value={packageId}
                  >
                    <option value={""} selected>
                      Select Below
                    </option>
                    {packageForExtend?.length > 0 &&
                      packageForExtend?.map((_plans: any) => {
                        return (
                          <option
                            key={_plans?.packageId}
                            value={_plans?.packageId}
                          >
                            {_plans?.packageName} , Rs{" "}
                            {commmaSeparator(_plans?.price)}{" "}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 odometer-rd">
                  {packageId && (
                    <div>
                      <label className="form-label" htmlFor="odometerReading">
                        Odometer Reading
                      </label>
                      <input
                        maxLength={9}
                        type="text"
                        name="odometerReading"
                        value={commmaSeparator(odometerReading)}
                        onChange={(e) => HandleOdometerReading(e)}
                        className="form-control form-control-sm"
                        inputMode="numeric"
                        pattern="[0-9]*"
                      />
                    </div>
                  )}
                </div>

                <div className="mt-3">
                  <div className="row">
                    <div className="col-sm-6">
                      {packageId && !additional_helmet && (
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rentAdditionalHelmet"
                            checked={additionalHelmet}
                            onChange={(e) => additionHelmetSelection()}
                          />
                          <label className="form-check-label">
                            {" "}
                            Rent Additional Helmet{" "}
                          </label>
                        </div>
                      )}
                      {additionalHelmet && (
                        <>
                          <p>
                            Click on helmets to rent them. Maximum one helmet!
                          </p>
                          <div className="d-flex rented-helmet">
                            {selectedPackage?.helmetConfiguration?.length &&
                              selectedPackage?.helmetConfiguration.map(
                                (item: any) => {
                                  return (
                                    <div
                                      className="p-2 cp"
                                      onClick={() => additionHelmetChange(item)}
                                    >
                                      <div
                                        className={`card text-center ${
                                          additionalHelmetData &&
                                          additionalHelmetData.helmetCatgoryId ===
                                            item.helmetCatgoryId
                                            ? "selectedHelmet"
                                            : ""
                                        }`}
                                      >
                                        <img
                                          src={
                                            item.helmetCatgoryName ===
                                            "Full Face"
                                              ? fullFace
                                              : halfFace
                                          }
                                          className="card-img-top"
                                          alt="Half Face Helmet"
                                        />
                                        <div className="card-body">
                                          <h6 className="card-title">
                                            {item.helmetCatgoryName}
                                          </h6>
                                          <h6>{`${item.hemletCharge}/-`}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center mt-1">
              <button
                disabled={!odometerReading}
                onClick={handleExtend}
                className="freedo-primary-button rounded-pill"
              >
                Calculate Charges
              </button>
            </div>
            {total_penalty_charge && (
              <div className="odometer-warning mb-3">
                <span className="text">
                  <strong>Note:</strong> Please enter the correct odometer
                  reading for calculating excess km charges. <br></br>
                  <b> Rs {total_penalty_charge} </b> penalty will be applied if
                  incorrect reading is submitted
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExtendRideModal;
