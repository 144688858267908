import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux";
import InputField from "./InputField";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { RootState } from "../redux/reducers";
import { GOOGLE_MAP } from "../constant/enum";
import { hindiTextValidation, restrictName, restrictNameWithSpaces } from "../utills/helper";
const containerStyle = {
 width: "100%",
 height: "48vh",
 marginTop: "10px",
};
const initiaState = {
  id: null,
  name: "",
  address: "",
  landmark: "",
  zipcode: "",
  city: "",
  latitude: 0,
  longitude: 0,
  saveAs: "",
  isDefaultAddress: false,
  formattedAddress: "",
  others: null,
  addAddressLoader: false
};
const AddressSection = ({ addressData, resetState, isEditAddress }) => {
 const dispatch = useDispatch();
 const { locale } = useSelector((state: RootState) => state.localeReducer);
 const addressModel = locale["addressModel"];
 const [state, setState] = useState(isEditAddress?addressData:initiaState);

 useEffect(() => {
  setState(addressData);
  if (!addressData?.id) geoFindMe();
 }, [addressData]);

 const onMapClick = (event) => {
  getAddress(event.latLng.lat(), event.latLng.lng());
 };

 const geoFindMe = () => {
  navigator.geolocation.getCurrentPosition(
   (position) => {
    getAddress(position.coords.latitude, position.coords.longitude);
   },
   (error) => {},
   { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
  );
 };
 const getAddress = (lat, lng) => {
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ location: { lat, lng } }, (results: any, status) => {
   if (status === "OK") {
    if (
     results[0]?.address_components.find((addressComponent) =>
      addressComponent.types.includes("postal_code")
     )?.short_name
    ) {
     setState({
      ...addressData,
      latitude: lat,
      longitude: lng,
      zipcode: results[0]?.address_components.find((addressComponent) =>
       addressComponent.types.includes("postal_code")
      )?.short_name,
      city: results[0]?.address_components.find((addressComponent) =>
       addressComponent.types.includes("administrative_area_level_3")
      )?.short_name,
      formattedAddress: results[0]?.formatted_address,
     });
    }
   } else {
    console.log(`Geocoder failed due to: ${status}`);
   }
  });
 };

 const onChange = (name: string, value: string | number) => {
  setState((preValue) => ({
   ...preValue,
   [name]: value,
  }));
 };

 const handleSaveAddress = () => {
   let payload = {
     ...state,
     address: btoa(state.address),
     landmark: btoa(state.landmark),
   };
   setState((prev) => ({ ...prev, addAddressLoader: true }));
   dispatch(
     actions.saveAddress({ params: payload, isEditAddress, handleSuccess, handleError })
   );
 };

 const handleSuccess = () => {
   setState({ ...initiaState, addAddressLoader: false });
   geoFindMe();
   resetState();
 };

 const handleError = () => {
   setState((prev: any) => ({ ...prev, addAddressLoader: false }));
 };

 return (
  <div className="row align-items-center add-new-address-wrap mt-3">
   <div className="col-md-12">
    <div className="map-details row">
     <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
       lat: state.latitude,
       lng: state.longitude,
      }}
      zoom={11}
      onClick={onMapClick}
     >
      <Marker
       position={{
        lat: state.latitude,
        lng: state.longitude,
       }}
       title={"any"}
      />
     </GoogleMap>
    </div>
    <form className="mt-3">
     <div className="mb-3">
      <label htmlFor="location" className="form-label">
       {" "}
       {addressModel["deliveryLocation"]}{" "}
      </label>
      <ReactGoogleAutocomplete
       apiKey= {GOOGLE_MAP.key}
       className="form-control"
       options={{
        types: ["(regions)"],
       }}
       placeholder="Select Location"
       value={state.formattedAddress}
       onChange={(e: any) => {
        setState({
         ...state,
         formattedAddress: e.target.value,
        });
       }}
       onPlaceSelected={(place: any) => {
        if (place?.geometry?.location)
         getAddress(
          place?.geometry?.location?.lat(),
          place?.geometry?.location?.lng()
         );
       }}
      />
     </div>

     <div className="mb-3">
      <label htmlFor="name" className="form-label">
       {" "}
       {addressModel["fullName"]}{" "}
      </label>
      <InputField
       className="form-control"
       type="text"
       placeholder={"Enter your name"}
       value={state.name}
       onChange={onChange}
       name={"name"}
       maxLength={50}
       restrictFuntion={restrictNameWithSpaces}
      />
     </div>

     <div className="mb-3">
      <label htmlFor="address" className="form-label">
       {" "}
       {addressModel["address"]}{" "}
      </label>
      <InputField
       className="form-control"
       type="text"
       placeholder={"Apartment No./Floor No./Road Name&No."}
       value={state.address}
       onChange={onChange}
       name={"address"}
       maxLength={100}
      />
     </div>

     <div className="mb-3">
      <label htmlFor="Landmark" className="form-label">
       {" "}
       {addressModel["landmark"]} <i> ({addressModel["optional"]}) </i>{" "}
      </label>
      <InputField
       className="form-control"
       type="text"
       placeholder={"Near/Opposite"}
       value={state.landmark}
       onChange={onChange}
       name={"landmark"}
       maxLength={50}
      />
     </div>

     <div className="mb-3">
      <label htmlFor="Landmark" className="form-label">
       {" "}
       {addressModel["saveAs"]}{" "}
      </label>
      <div>
       {(addressModel["addressType"] as []).map((type: any, ind: number) => (
        <span
         key={ind}
         className={`btn-home smallest-text ${
          type.name === state.saveAs && "active"
         }`}
         onClick={() => onChange("saveAs", type.name)}
        >
         <i className={type.className}></i>
         &nbsp;&nbsp;{type.label}
        </span>
       ))}
      </div>
     </div>

     <div className="form-check mb-3">
      <InputField
       value={"isDefaultAddress"}
       name={"isDefaultAddress"}
       type={"checkbox"}
       className="form-check-input"
       onChange={(name, value, checked) =>
        setState({
         ...state,
         [name]: checked,
        })
       }
       isChecked={state.isDefaultAddress}
       label={addressModel["useAsDefault"]}
      />
     </div>

     <button
      className="freedo-primary-button rounded-pill d-block text-dark text-center w-100"
      type="button"
      onClick={handleSaveAddress}
      disabled={state.addAddressLoader}>
      {addressModel["saveAddress"]}{" "}
     </button>
    </form>
   </div>
  </div>
 );
};

export default AddressSection;
