import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { editIcon, oldphone } from "../../../assets";
import NewInputField from "../../../component/NewInputField";
import OtpInput from "../../../component/OTPField";
import { actions } from "../../../redux";
import { RootState } from "../../../redux/reducers";
import profileServices from "../../../redux/services/profileServices";
import { restrictNameWithSpaces, validate } from "../../../utills/helper";
import { checkNumberInput } from "../../../utills/regex";

const intitalEmergency = {
  relation_name: "",
  emergency_number: "",
  relation: "",
};
const EmergencyContact = (props: any) => {
  const { userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );
  const dispatch = useDispatch();
  const handleScroll = useRef<any>(null);
  const inputRef = useRef<any>(0);

  const [emergencyContactData, setEmergencyContactData] =
    useState<any>(intitalEmergency);
  const [relationList, setRelationList] = useState<[]>([]);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isVerifyUI, setIsVerifyUI] = useState<boolean>(false);
  const [otpValue, setOtpValue] = useState<any>("");
  const [otpError, setOtpError] = useState<any>("");
  const [errorFields, setErrorFields] = useState({
    relation_name: "",
    emergency_number: "",
    relation: "",
  });
  const [isContactVerified, setIsContactVerified] = useState<any>(
    userProfileDetail?.emergency_number_verified
  );
  useEffect(() => {
    profileServices.getRelationList().then((response: any) => {
      if (response && response.status === 200) {
        const list = response.data?.Result ?? [];
        setRelationList(list);
      }
    });
  }, []);
  useEffect(() => {
    const newDetail = {
      relation_name: userProfileDetail?.relation_name,
      emergency_number: userProfileDetail?.emergency_number,
      relation: userProfileDetail?.relation,
    };
    setEmergencyContactData(newDetail);
  }, [userProfileDetail]);
  const handleChange = (name: string, value: string) => {
    setEmergencyContactData({
      ...emergencyContactData,
      [name]: value,
    });
    setIsUpdated(true);
    setErrorFields({
      ...errorFields,
      [name]: "",
    });
    props.handleValuechange(name, value);
  };
  const handleChangeOTP = (name: string, value: string) => {
    setOtpValue(value);
    setOtpError("");
  };

  useEffect(() => {
    if (!isContactVerified) {
      handleScroll.current?.scrollIntoView({
        behavior: "smooth",
      });

      inputRef.current.focusInput();
    }
  }, [isContactVerified]);

  const handleSelectChange = (e: any) => {
    const value = e.target.value;
    setIsUpdated(true);
    setEmergencyContactData({
      ...emergencyContactData,
      [e.target.name]: value,
    });
    setErrorFields({
      ...errorFields,
      [e.target.name]: "",
    });
    props.handleValuechange(e.target.name, value);
  };

  const validateForm = (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validate(emergencyContactData, errorFields);
    console.log(validation.errorFields);
    if (validation.error) {
      return setErrorFields({ ...errorFields, ...validation.errorFields });
    }

    const relationNameFiltrd = relationList.filter(
      (e: any) => e.name == emergencyContactData.relation
    );
    const relationName = relationNameFiltrd.length
      ? relationNameFiltrd[0]["name"]
      : "";
    const payload = {
      relation: relationName,
      relationName: emergencyContactData.relation_name,
      emergencyNumber: emergencyContactData.emergency_number,
    };
    dispatch(actions.postUpdateUserProfile(payload));
    setIsUpdated(false);
  };

  const callSendOtpVerifyNumber = () => {
    dispatch(actions.sendOtpForEmergencyNumber({ setIsVerifyUI }));
  };
  const callVerifyOtpNumber = () => {
    console.log("calleddd");
    if (otpValue.length !== 6) {
      setOtpError("Please enter correct OTP");
      return;
    }
    const payload = {
      otp: Number(otpValue),
    };

    dispatch(
      actions.verifyOtpForEmergencyNumber({
        payload,
        setIsVerifyUI,
        setIsContactVerified,
      })
    );
  };
  const isNumberSaved =
    userProfileDetail?.emergency_number !== null &&
    userProfileDetail?.emergency_number !== "";

  /*   let isContactVerified = userProfileDetail?.emergency_number_verified;
   */
  const getRelationNameByID = (idd: number) => {
    const filtrd = relationList.filter((e: any) => e.id === idd);
    if (filtrd.length) {
      return filtrd[0]["name"];
    } else {
      return "";
    }
  };
  const renderVerifiedUI = () => {
    return (
      <div>
        <h6>
          <img src={oldphone} alt="" /> Emergency Details
        </h6>

        <div className="pt-3">
          <div className="title-description col-md-12">
            <h6>Contact Name</h6>
            <p>{emergencyContactData?.relation_name}</p>
          </div>
          <div className="title-description col-md-12">
            <h6>Contact Number</h6>
            <p>{emergencyContactData?.emergency_number}</p>
          </div>
          <div className="title-description col-md-12">
            <h6>Relation with Rider</h6>
            {/* <p>{getRelationNameByID(emergencyContactData?.relation)}</p> */}
            <p>{emergencyContactData?.relation}</p>
          </div>
          <div className="success-text">
            <p>Your emergency contact is verified!</p>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <a
              href="#"
              onClick={() => {
                setIsContactVerified(!isContactVerified);
              }}
            >
              <img src={editIcon} alt="edit" /> Edit Emergency Details{" "}
            </a>
          </div>
        </div>
      </div>
    );
  };
  const newVerifyUI = () => {
    return (
      <div className="flip-card-back">
        <div className="verify-em text-center">
          <h5>Verification</h5>
          <p>
            Enter the OTP sent to{" "}
            <b>{`+91${userProfileDetail?.emergency_number}`}</b>
          </p>
          <div className="mt-3">
            <OtpInput
              handleChange={(otp: string) => handleChangeOTP("otp", otp)}
            />
            <button
              className="freedo-primary-button-small rounded-pill w-50"
              onClick={() => callVerifyOtpNumber()}
              disabled={otpValue.length < 6}
            >
              Verify
            </button>
            <p className="resend text-muted mb-0 pt-2">
              Didn't receive code?{" "}
              <Link to="" onClick={callSendOtpVerifyNumber}>
                Request again
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const emergrncyContactDetails = () => {
    return (
      <div className={`flip-card-inner ${!isVerifyUI ? "front" : "back"} `}>
        <div className={`flip-card-front ${isVerifyUI && "d-none"}`}>
          <h6>
            <img src={oldphone} alt="" /> Emergency Details
          </h6>
          <form className="row mt-4" onSubmit={validateForm}>
            <div className="col-md-12" ref={handleScroll}>
              <label htmlFor="inputcontactname4" className="form-label">
                Contact Name
              </label>
              <NewInputField
                ref={inputRef}
                value={
                  emergencyContactData.relation_name
                    ? emergencyContactData.relation_name
                    : ""
                }
                name={"relation_name"}
                type={"text"}
                className="form-control"
                onChange={handleChange}
                placeholder="Enter your emergency contact name"
                restrictFuntion={restrictNameWithSpaces}
              />
              {errorFields["relation_name"] !== "" && (
                <div className="error-field-container">
                  <span className="error-field">
                    {errorFields["relation_name"]}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="inputContactNumber4" className="form-label">
                Contact Number
              </label>

              <NewInputField
                value={
                  emergencyContactData.emergency_number
                    ? emergencyContactData.emergency_number
                    : ""
                }
                name={"emergency_number"}
                type={"text"}
                className="form-control"
                onChange={handleChange}
                maxLength={10}
                placeholder="Enter your emergency contact number"
                restrictFuntion={checkNumberInput}
                inputMode={"numeric"}
                pattern="[0-9]*"
              />
              {errorFields["emergency_number"] !== "" && (
                <div className="error-field-container">
                  <span className="error-field">
                    {errorFields["emergency_number"]}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="inputContactNumber4" className="form-label">
                Relation with Rider
              </label>
              <select
                id="inputState"
                className="form-select"
                value={
                  emergencyContactData.relation
                    ? emergencyContactData.relation
                    : ""
                }
                name="relation"
                onChange={handleSelectChange}
              >
                <option>Select relation</option>
                {relationList.map((item: any, indx: number) => {
                  return (
                    <option key={indx} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errorFields["relation"] !== "" && (
                <div className="error-field-container">
                  <span className="error-field">{errorFields["relation"]}</span>
                </div>
              )}
            </div>
            {isUpdated && (
              <button className="freedo-primary-button-small rounded-pill pt-2">
                {" "}
                Save{" "}
              </button>
            )}
            {isNumberSaved && !isUpdated && (
              <p className="small-text">
                Saved Emergency contact is{" "}
                {userProfileDetail?.emergency_number_verified &&
                userProfileDetail?.emergency_number ===
                  emergencyContactData?.emergency_number
                  ? "already Verified,"
                  : "not Verified,"}
                {userProfileDetail?.emergency_number_verified &&
                userProfileDetail?.emergency_number !==
                  emergencyContactData?.emergency_number ? (
                  <Link
                    to="#"
                    className="text-decoration-underline"
                    onClick={callSendOtpVerifyNumber}
                  >
                    Verify Now
                  </Link>
                ) : null}
              </p>
            )}
          </form>
        </div>
        {newVerifyUI()}
      </div>
    );
  };

  return (
    <div className="col-md-4">
      <div className="emergency-wrap">
        {isContactVerified ? (
          renderVerifiedUI()
        ) : (
          <div className="flip-card">{emergrncyContactDetails()}</div>
        )}
      </div>
    </div>
  );
};

export default EmergencyContact;
