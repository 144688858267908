import { useSelector } from "react-redux";
import { delv } from "../../assets";
import { RootState } from "../../redux/reducers";

import AddressListModel from "../../component/AddressListModel";
import en from "../../locales/en.json";
import { GoogleMapComponent } from "./GoogleMapComponent";
const intialData = {
  pickupAddress: null,
  homeAddress: null,
};

const CheckoutAddress = (props: any) => {
  // console.log(props);
  const {
    branchDetails,
    checkData,
    setBranchName,
    handleUpdateData,
    setSelectedCoupon,
    setAdditionalHelmet,
    setAdditionalHelmetData
  } = props;
  const { selectedBranch, homeDelivery, selectedHomeAddress } = checkData;
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const addressModel = locale["addressModel"];

  const { checkout } = en;
  // console.log(branchDetails);

  const selectdMarkerLocation = (item: any) => {
    props.handleBranchChange(item);
  };

  const handleHomeAddressChange = (item: any) => {
    props.handleAddressSelected(item);
  };
  return (
    <>
      <GoogleMapComponent
        branchDetails={branchDetails}
        selectdMarkerLocation={selectdMarkerLocation}
        setBranchName={setBranchName}
        handleUpdateData={handleUpdateData}
        setSelectedCoupon={setSelectedCoupon}
        setAdditionalHelmet={setAdditionalHelmet}
        setAdditionalHelmetData={setAdditionalHelmetData}
      />

      {homeDelivery && (
        <div className="delivery-details row">
          <div className="col-md-7">
            <img src={delv} className="delv-image" />
            <span className="del-detail">{checkout.deliverHere}</span>
            <span className="head">{selectedHomeAddress?.name}</span>
            <span className="text">
              {selectedHomeAddress?.address1
                ? selectedHomeAddress?.address1
                : en.errorMessages.addAddressHomeDelivery}
            </span>
            <span>
              {(addressModel["addressType"] as [])?.map(
                (type) =>
                  selectedHomeAddress?.user_addresses?.save_as ===
                    type["name"] && (
                    <button className="btn-home smallest-text cursor-default">
                      <i className={type["className"]} aria-hidden="true"></i>
                      &nbsp;&nbsp;{type["name"]}
                    </button>
                  )
              )}
            </span>
          </div>
          <div className="col-md-5 col-sm-5 text-end change-address-wrapper">
            <button
              className="freedo-outline-btn rounded-pill"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvaschangeaddress"
              aria-controls="offcanvaschangeaddress"
            >
              {selectedHomeAddress
                ? checkout.changeAddress
                : checkout.addAddress}
            </button>
            <AddressListModel
              addAddess={!selectedHomeAddress}
              handleChange={handleHomeAddressChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutAddress;
