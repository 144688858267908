import React, { useEffect, useState } from "react";
import "./component.css";
import "../themes/global.css";
import { useDispatch } from "react-redux";
import { getVehicleDetailsAction } from "../redux/actions";
import Modal from "react-bootstrap/Modal";
const initialState = {
  type: "",
  brand: "",
  model: "",
  consumption: "",
  hp: "",
  cc: "",
  images: [],
};
interface vecleInterface {
  type: string;
  brand: string;
  model: string;
  consumption: string;
  hp: string;
  cc: string;
  images: Array<string>;
}
const CustomModalVehicleDetail = ({
  showBookVehicle,
  setOpenDetailModal,
  handleBookVehicle,
  openDetailModal,
  modelId,
  rentingFleetLoader,
}: any) => {
  const [vehicleData, setVehicleData] = useState<vecleInterface>(initialState);
  const { type, brand, model, consumption, hp, cc } = vehicleData;
  const [modalImagePath, setModalImagePath] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (openDetailModal) {
      dispatch(getVehicleDetailsAction({ modelId }, handleSuccess));
    }
  }, [openDetailModal]);

  const handleSuccess = (data) => {
    const vehicleDetails = data?.vehicleDetails;
    const modalImagePath = data?.imagePath;
    if (modalImagePath) setModalImagePath({ ...modalImagePath });
    if (vehicleDetails) setVehicleData({ ...vehicleDetails });
  };

  const removeSpaces = (str) => {
    return str.replace(/\s/g, "%20");
  };
  return (
    <Modal
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={openDetailModal}
      className="modal fade vehicle-model"
      id="bookvehicle"
      tabIndex={-1}
      aria-hidden="true"
    >
      <Modal.Header
        closeButton
        onClick={() => {
          setOpenDetailModal(false);
          setVehicleData(initialState);
          setModalImagePath({});
        }}
      >
        <Modal.Title>
          <h6 className="modal-title" id="bookvehicleLabel">
            Vehicle Details
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row vehicle-specs" style={{ marginBottom: "15px" }}>
          <div className="col-md-6">
            <div
              id="vehicleindicator"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                {vehicleData?.images?.length > 0 &&
                  vehicleData.images.map((image, ind) => (
                    <button
                      style={{
                        backgroundImage: `url(${removeSpaces(
                          `${
                            ind == 0
                              ? modalImagePath?.vehicleImagePath
                              : modalImagePath?.vehcleDetailViewPath 
                          }` + image
                        )})`,
                        backgroundPosition: "center",
                      }}
                      key={ind}
                      type="button"
                      data-bs-target="#vehicleindicator"
                      data-bs-slide-to={ind}
                      className={`${"slider-" + (ind + 1)} ${
                        ind == 0 && "active"
                      }`}
                      aria-current="true"
                      aria-label={`${"Slide" + " " + (ind + 1)}`}
                    ></button>
                  ))}
              </div>
              <div className="carousel-inner">
                {vehicleData?.images?.length > 0 &&
                  vehicleData.images.map((image, ind) => (
                    <div
                      key={ind}
                      className={`carousel-item ${ind === 0 && "active"}`}
                    >
                      <img
                        className="w-100 img-responsive"
                        src={`${removeSpaces(
                          `${
                            ind == 0
                              ? modalImagePath?.vehicleImagePath
                              : modalImagePath?.vehcleDetailViewPath 
                          }` + image
                        )}`}
                        alt="..."
                      />
                    </div>
                  ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#vehicleindicator"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#vehicleindicator"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bike-sp-wrap">
              <h6 className="bike-brand">{brand}</h6>
              <h4>{model}</h4>

              <h5 className="specific mt-3">Specification</h5>
              <ul className="specific-list">
                <li>
                  <span>Type:</span>
                  <span>{type}</span>
                </li>
                <li>
                  <span>Brand:</span>
                  <span>{brand || "N/A"}</span>
                </li>

                <li>
                  <span>Model:</span>
                  <span>{model || "N/A"}</span>
                </li>

                <li>
                  <span>Consumption:</span>
                  <span>{consumption || "N/A"}</span>
                </li>

                <li>
                  <span>HP:</span>
                  <span>{hp || "N/A"}</span>
                </li>

                <li>
                  <span>CC:</span>
                  <span>{cc || "N/A"}</span>
                </li>
              </ul>
            </div>
            {showBookVehicle && (
              <button
                className="freedo-primary-button rounded-pill w-100 mt-4"
                onClick={() => {
                  handleBookVehicle();
                }}
              >
                {rentingFleetLoader ? (
                  <div className="spinner-border spinner-border-sm text-light">
                    <div className="visually-hidden" aria-hidden="true">
                      Loading...
                    </div>
                  </div>
                ) : (
                  <>Book Vehicle</>
                )}
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <div className="modal-footer border-0 mb-3"></div>
      </Modal.Footer> */}
    </Modal>
  );
};

export default CustomModalVehicleDetail;
