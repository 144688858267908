import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DeliveryMode from "../../component/DeliveryMode";
import SelectTimeSlot from "../../component/SelectTimeSlot";
import { RAZORPAY_KEY } from "../../config/apiUrls";
import { LocalStorage } from "../../constant/enum";
import { Routes } from "../../constant/routeContants";
import en from "../../locales/en.json";
import {
  createBooking,
  getHomeAddressList,
  processOrder,
  verifyOrderPayment,
} from "../../redux/actions";
import { RootState } from "../../redux/reducers";
import { EventApi } from "../../utills/eventApi";
import { FirebaseEvents } from "../../utills/firebaseEvents";
import { getLocalStorageData, setLocalStorageData } from "../../utills/helper";
import BilligDetails from "./BillingDetails";
import CheckoutAddress from "./CheckoutAddress";
import CheckoutProductDetail from "./CheckoutProductDetail";
import { checkoutScreenImg } from "../../assets";
var CryptoJS = require("crypto-js");
export const DEFAULT_DATA = {
  homeDelivery: false,
  bikeData: {},
  slot: null,
  selectedBranch: null,
  selectedHomeAddress: null,
  selectedCoupon: null,
  homeDeliveryCharge: null,
  AdditionHelmetCharge: 0,
  helmetData: null,
  isCOD: false,
};
const CheckoutScreen = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const basicFilters = state?.basicFilters;
  const vehicleData = state?.vehicleData;
  const { checkout } = en;
  const dispatch = useDispatch();
  const [checkoutData, setCheckoutData] = useState<any>(DEFAULT_DATA);
  const [finalAmount, setFinalAmount] = useState<any>(0);
  const [selectedCouponData, setSelectedCoupon] = useState<any>(null);
  const [additionalHelmet, setAdditionalHelmet] = useState<boolean>(false);
  const [additionalHelmetData, setAdditionalHelmetData] = useState<any>(null);
  const [payNowLoader, setPayNowLoader] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>("");
  const {
    homeAddressList,
    addressNotDeliverable,
    vehicleData: vehicleDataFromApi,
    branchSlots,
  } = useSelector((state: RootState) => state.checkoutReducer);

  const { userData } = useSelector((state: RootState) => state.authReducer);
  const userCity = getLocalStorageData(LocalStorage.userCity);
  const isVehicleData = vehicleData && Object.keys(vehicleData).length;

  if (!isVehicleData) navigate("/");

  useEffect(() => {
    dispatch(getHomeAddressList({ payload: {} }));
  }, [vehicleData]);

  const handleLoader = () => setPayNowLoader(false);

  useEffect(() => {
    console.log("Summary_Screen", {});
    FirebaseEvents("Summary_Screen", {});
  }, []);

  useEffect(() => {
    if (homeAddressList.length) {
      const filtrdDefault = homeAddressList?.filter((e) =>
        checkoutData?.selectedHomeAddress
          ? e.id == checkoutData?.selectedHomeAddress.id
          : e.user_addresses.is_default === true
      );
      if (filtrdDefault.length) {
        const data = { ...checkoutData };
        data.selectedHomeAddress = filtrdDefault[0];
        setCheckoutData({ ...data });
      }
    }
  }, [homeAddressList, checkoutData.homeDelivery]);

  const handleDeliveryChange = (type: any, value: any) => {
    const data = { ...checkoutData };
    if (type == "changeData") {
      data.homeDeliveryCharge = value;
    } else {
      if (!value) data.homeDeliveryCharge = null;
      data.homeDelivery = value;
    }
    setCheckoutData(data);
    setSelectedCoupon(null);
    setAdditionalHelmet(false);
    setAdditionalHelmetData(false);
    setCheckoutData((prevFields: any) => ({
      ...prevFields,
      slot: null,
    }));
  };
  const handleSlotChange = (value: any) => {
    const data = { ...checkoutData };
    data.slot = value;
    setCheckoutData(data);
  };
  const handleBranchSelected = (item: any) => {
    const data = { ...checkoutData };
    data.selectedBranch = item;
    setCheckoutData(data);
  };
  const handleHomeAddressSelected = (item: any) => {
    const data = { ...checkoutData };
    data.selectedHomeAddress = item;
    setCheckoutData(data);
  };
  const handleChangeDataUpdate = (type: string, value: any) => {
    const data = { ...checkoutData };
    data[type] = value;
    setCheckoutData(data);
  };
  const handleCheckEmergencyValidate = () => {
    if (branchSlots?.length === 0) {
      toastr.error("", "Slots not Available");
      return;
    }
    if (
      checkoutData.selectedBranch === null &&
      checkoutData.selectedHomeAddress === null
    ) {
      toastr.error(
        "",
        "Please select a pickup branch or delivery address to proceed"
      );
      return;
    }

    if (checkoutData.slot === null) {
      toastr.error("", "Please select time slot to proceed");
      return;
    }

    if (checkoutData.slot?.id === undefined) {
      toastr.error("", "Please select time slot to proceed");
      return;
    }
    if (checkoutData.homeDelivery && !checkoutData.selectedHomeAddress?.id) {
      toastr.error("", "Please add address to proceed");
      return;
    }

    setPayNowLoader(true);
    handleCreateBooking();
  };
  const navigateToProfile = () => {
    navigate(Routes.PROFILE, {
      state: { values: "my-profile" },
    });
  };
  const handleCreateBooking = () => {
    const selectdSlot = checkoutData.slot;
    const latLongg = {
      latitude: checkoutData.selectedBranch
        ? checkoutData.selectedBranch?.latitude
        : 0.0,
      longitude: checkoutData.selectedBranch
        ? checkoutData.selectedBranch?.longitude
        : 0.0,
    };
    const bookingData = {
      coupon_code: checkoutData.selectedCoupon
        ? checkoutData.selectedCoupon.id
        : "",
      address_id:
        checkoutData.homeDelivery === true
          ? checkoutData.selectedHomeAddress?.id
          : null,
      branch_id: checkoutData.selectedBranch?.id,
      rental_plan_id: checkoutData.selectedBranch?.planId,
      time_slot_id: selectdSlot ? selectdSlot?.id : 0,
      city_id: basicFilters?.city?.id ? basicFilters?.city?.id : userCity?.id,
      end_date: moment(basicFilters?.endDate).format("YYYY-MM-DD"),
      end_time: selectdSlot ? selectdSlot?.to_time : "",
      mode_of_pick_up_id: checkoutData.homeDelivery ? 1 : 2,
      package_id: basicFilters?.package?.id,
      pick_up_delivery_charges:
        checkoutData?.homeDeliveryCharge?.home_delivery_charge || 0,
      vehicle_model_id: vehicleData?.id,
      start_date: moment(basicFilters?.date).format("YYYY-MM-DD"),
      start_time: selectdSlot ? selectdSlot?.from_time : "",
      type: "STORE_BOOKING_REQUEST",
      user_id: userData?.id,
      additonal_helmet_charge: checkoutData.helmetData
        ? checkoutData.helmetData?.helmetCharges
        : 0,
      helmet_category_id: checkoutData.helmetData
        ? checkoutData.helmetData?.helmetCategoryMaster?.id
        : null,
      complementary_charge: true,
      additional_helmet: checkoutData.helmetData ? true : false,
      location_id: checkoutData.selectedBranch?.locationId,
      is_cod: checkoutData.isCOD,
      latlong: latLongg,
      delivery_cityid: checkoutData.homeDelivery
        ? checkoutData.homeDeliveryCharge?.id
        : null,
      security_deposit: checkoutData.selectedBranch?.securityAmt
        ? checkoutData.selectedBranch?.securityAmt
        : 0,
      request_from: "web",
      test_ride: vehicleData?.testRides || false,
      source_id: null,
      operator_rz_id: checkoutData?.selectedBranch?.operatorRzId,
      vehicle_category_id: vehicleDataFromApi?.vehicle_category_id,
    };
    dispatch(
      createBooking({
        payload: bookingData,
        callBack: callbackHandle,
        handleLoader,
      })
    );
  };
  const callbackHandle = (resp: any) => {
    console.log("callback", resp);
    if (resp) {
      let respObj = checkoutData.isCOD ? resp.Result[0] : resp.Result;
      if (respObj?.amount === 0 || checkoutData.isCOD) {
        console.log("COD", {});
        FirebaseEvents("COD", {});
        setPayNowLoader(false);
        {
          /* added below checks bcz differnt response coming from api in case cod */
        }
        navigate(
          `/payment-success-response-handler/${
            respObj.order_id || respObj.orderId
          }`,
          {
            state: {
              ...respObj,
              bookingId: respObj.booking_id,
              orderId: respObj.order_id,
              transactionId: respObj.transaction_id,
              transactionDate: respObj.transaction_date,
            },
          }
        );
      } else {
        callOrderApi(
          resp.Result.bookingId
            ? resp.Result.bookingId
            : resp.Result.booking_id,
          resp.Result.amount,
          resp.Result.userId,
          resp.Result?.helpData || {},
          resp.Result.security
        );
      }
    }
  };
  const callOrderApi = async (
    bookingid: any,
    amount: any,
    userID: any,
    helpData: any,
    security
  ) => {
    let tempAmount = parseFloat(amount).toFixed(2);
    let responseData = {
      data: encryptRazorPay({
        amount: Number(tempAmount),
        bookingId: bookingid,
        // Platform: Platform.OS,
        userId: userID,
        paidFor: checkoutData.helmetData?.additionalHelmet
          ? checkoutData?.homeDelivery == 1
            ? 3
            : 2
          : 1,
        helpData: helpData,
        security: security,
        operatorId:
          checkoutData.selectedBranch &&
          checkoutData?.selectedBranch?.operatorRzId,
        branchId: checkoutData.selectedBranch?.id,
      }),
    };
    dispatch(
      processOrder({
        payload: responseData,
        callBack: handleProcessOrderCallback,
        handleLoader,
      })
    );
  };

  ///facebook and firebase analytics service call
  const analyticsEventCall = () => {
    const eventData = {
      data: [
        {
          event_name: "Initiate_Checkout",
          event_time: new Date(),
          event_source_url: window.location.href,
          user_data: {
            client_ip_address: "1.2.3.4",
            client_user_agent: "*",
          },
        },
      ],
    };
    EventApi(eventData);
  };

  const handleProcessOrderCallback = (data: any, stopLoader) => {
    const selectdSlot = checkoutData.slot;
    let firebaseObject = {
      latitude: checkoutData.selectedBranch?.latitude || null,
      longitude: checkoutData.selectedBranch?.longitude || null,
      slot: selectdSlot?.from_time + "-" + selectdSlot?.to_time,
      type: checkoutData.homeDelivery ? "Home Delivery" : "Pickup",
      price: data?.Result?.amount,
      currency: data?.Result?.currency,
    };
    FirebaseEvents("PayNow", firebaseObject);
    console.log("PayNow", firebaseObject);
    console.log("process callback", data);
    setOrderId(data.Result.id);
    setFinalAmount(data.Result.amount);
    displayRazorpay(
      data.Result.id,
      data.Result.amount,
      stopLoader,
      data?.Result?.rzpkey
    );
    analyticsEventCall();
  };
  const encryptRazorPay = (plainText: any) => {
    // var hash = CryptoJS.SHA512(KYC_VERIFY_PASSWORD);
    console.log("razor pay before encrypting", plainText);
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(plainText),
      "@dmin"
    ).toString();

    return ciphertext;
  };
  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const displayRazorpay = async (
    orderid: any,
    amount: any,
    stopLoader,
    rzpKey: string
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      stopLoader();
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    } else stopLoader();
    const options = {
      key: rzpKey ? rzpKey : RAZORPAY_KEY,
      // Enter the Key ID generated from the Dashboard
      // amount: parseInt(final_total),
      amount: parseInt(amount),
      currency: "INR",
      timeout: 300,
      name: "Freedo Rentals",
      order_id: orderid,
      description: "Credits towards Freedo Rentals",
      prefill: {
        name: userData?.first_name,
        email: userData?.email_id,
        contact: userData?.mobile_number,
      },
      theme: {
        color: "#2BC7C9",
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "All payment methods",
              instruments: [
                {
                  method: "upi",
                },
                {
                  method: "card",
                },
                {
                  method: "wallet",
                },
                {
                  method: "netbanking",
                },
                {
                  method: "emi",
                },
                {
                  method: "cardless_emi",
                },
              ],
            },
          },
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: false,
          },
        },
      },

      // image: { logo },
      handler: async function (response: any) {
        // const data = {
        //   orderCreationId: order_id,
        //   razorpayPaymentId: response.razorpay_payment_id,
        //   razorpayOrderId: response.razorpay_order_id,
        //   razorpaySignature: response.razorpay_signature,
        // };
        const reqPayload = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          //razorpay_signature: response.razorpay_signature,
        };
        dispatch(
          verifyOrderPayment({
            payload: reqPayload,
            callBack: paymentVerificationCallback,
          })
        );
      },
    };
    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  };
  const paymentVerificationCallback = (response: any) => {
    if (response?.data) {
      let firebaseObject = {
        amount: response.data?.Result?.amount,
        date: moment(response.data?.Result?.transactionDate).format(
          "DD-MM-YYYY"
        ),
      };
      FirebaseEvents("PaymentSuccess", firebaseObject);
      console.log("PaymentSuccess", firebaseObject);
      if (response.data?.Result?.paymentStatus == "pending") {
        window.open(
          `${process.env.PUBLIC_URL}/payment-pending-web/${response.data.Result.orderId}`
        );
      } else {
        console.log("this is success response of verify 26", response.data);
        // navigate(
        //   `/payment-success-response-handler/${response.data.Result.orderId}`,
        //   { state: { ...response.data.Result } }
        // );

        // if we use react navigation then we were unable to log gtag so we are using window.location for that
        setLocalStorageData(LocalStorage.bookingDetails, {
          state: { ...response.data.Result },
        });
        window.location.href = `/payment-success-response-handler/${response.data.Result.orderId}`;
      }
    } else {
      console.log("PaymentFailure", {});
      FirebaseEvents("PaymentFailure", {});
      toastr.error("Oops", response?.data?.result?.Message);
    }
  };
  const getTimeSlotFilterData = () => {
    return {
      branch_id: checkoutData.selectedBranch?.id,
      date: basicFilters.date,
      option_id: checkoutData.homeDelivery ? 2 : 3,
    };
  };

  //set branch name
  const setBranchName = (value: any) => {
    const data = { ...checkoutData };
    data.selectedBranch = value;
    setCheckoutData(data);
  };

  //if one branch then select
  useEffect(() => {
    if (vehicleData?.branchDetails?.length === 1) {
      setBranchName(vehicleData?.branchDetails[0]);
    }
  }, []);

  return (
    <section className="checkout-wrapper">
      <div className="container-fluid">
        <div className="main-content-wrapper">
          <div className="check-out-step-wrap">
            <div className="steps-inner-wrap">
              <a className="steps-body visited">
                <span className="number"> 1 </span>
                <h6>{checkout.selectVehicle}</h6>
                <div className="steps-border"></div>
              </a>
              <a className="steps-body visited">
                <span className="number"> 2 </span>
                <h6>{checkout.reviewBooking}</h6>
                <div className="steps-border"></div>
              </a>
              <a className="steps-body active">
                <span className="number"> 3 </span>
                <h6>{checkout.payment}</h6>
              </a>
            </div>
          </div>

          <h3>{checkout.checkout}</h3>
          <div className="breadcrumb-wrap">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <Link to={Routes.RENT}>Renting Fleet</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Checkout
                </li>
              </ol>
            </nav>
          </div>

          <div className="row">
            <div className="col-md-7">
              {checkoutData.selectedBranch && (
                <DeliveryMode
                  handleChange={handleDeliveryChange}
                  cityData={basicFilters?.city}
                  branchID={isVehicleData ? vehicleData.lowestPriceBranchId : 0}
                  addressData={checkoutData?.selectedHomeAddress}
                  selectedBranch={checkoutData.selectedBranch}
                />
              )}

              {isVehicleData ? (
                <CheckoutAddress
                  checkData={checkoutData}
                  branchDetails={vehicleData?.branchDetails}
                  handleBranchChange={handleBranchSelected}
                  handleAddressSelected={handleHomeAddressSelected}
                  setBranchName={setBranchName}
                  handleUpdateData={handleChangeDataUpdate}
                  setSelectedCoupon={setSelectedCoupon}
                  setAdditionalHelmet={setAdditionalHelmet}
                  setAdditionalHelmetData={setAdditionalHelmetData}
                />
              ) : undefined}
              {isVehicleData ? (
                <CheckoutProductDetail
                  productData={vehicleData}
                  rentData={basicFilters}
                />
              ) : undefined}
              {checkoutData.selectedBranch && (
                <SelectTimeSlot
                  deliveryType={checkoutData.homeDelivery}
                  slot={checkoutData.slot}
                  filterData={getTimeSlotFilterData()}
                  handleChange={handleSlotChange}
                />
              )}
            </div>

            {checkoutData.selectedBranch ? (
              <BilligDetails
                selectedBranchData={checkoutData.selectedBranch}
                homeDeliverCharges={checkoutData.homeDeliveryCharge}
                isCOD={checkoutData.isCOD}
                handleUpdateData={handleChangeDataUpdate}
                handlePayClick={handleCheckEmergencyValidate}
                model_id={vehicleData?.id}
                packageId={basicFilters?.package?.id}
                payNowLoader={payNowLoader}
                nevigate={navigateToProfile}
                addressNotDeliverable={addressNotDeliverable}
                isHomeDelivery={checkoutData?.homeDelivery}
                branchData={checkoutData.selectedBranch}
                productData={vehicleData}
                selectedCouponData={selectedCouponData}
                setSelectedCoupon={setSelectedCoupon}
                additionalHelmet={additionalHelmet}
                setAdditionalHelmet={setAdditionalHelmet}
                additionalHelmetData={additionalHelmetData}
                setAdditionalHelmetData={setAdditionalHelmetData}
              />
            ) : (
              <div className="col-12 col-md-5 select-hub-wrapper">
                <img src={checkoutScreenImg} style={{ width: "160px" }} />
                <div className="select-hub-box">
                  <h3>No Hub Selected</h3>
                  <p> Please select any hub to view the payment details</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckoutScreen;
