import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { timeSlot123 } from "../assets";
import en from "../locales/en.json";
import { getBranchSlots } from "../redux/actions";
const SelectTimeSlot = (props: any) => {
  const dispatch = useDispatch();
  const { branchSlots } = useSelector(
    (state: RootState) => state.checkoutReducer
  );
  const { option_id, branch_id, date } = props.filterData;
  const [selectedSlot, setSelectedSlot] = useState<number>(1);
  const [selectedtimeID, setSelectedTimeID] = useState<number>(0);
  const availableSlots = { ...branchSlots };
  const { timeSlot } = en;

  useEffect(() => {
    let timeSlot;
    if (branchSlots?.morning?.length) {
      timeSlot = branchSlots?.morning[0].timeSlot;
      setSelectedSlot(1);
    } else if (branchSlots?.afternoon?.length) {
      timeSlot = branchSlots?.afternoon[0].timeSlot;
      setSelectedSlot(2);
    } else if (branchSlots?.evening?.length) {
      timeSlot = branchSlots?.evening[0].timeSlot;
      setSelectedSlot(3);
    }
    if (timeSlot) {
      handletimechange(timeSlot);
    }
  }, [branchSlots]);

  useEffect(() => {
    dispatch(getBranchSlots(props.filterData));
    if (props.slot) {
      setSelectedSlot(1);
      setSelectedTimeID(0);
      props.handleChange(null);
    }
  }, [option_id,date,branch_id]);
  const handletimechange = (timeSlot: any) => {
    const timeid = timeSlot.id;
    setSelectedTimeID(timeid);
    // props.handleChange({ "slot_id": selectedSlot, "time_id": selectedtimeID, "timeSlot": timeSlot })
    props.handleChange(timeSlot);
  };

  return (
    <>
      <div className="time-slot-details row">
        <div className="col-md-12">
          <img className="time-slot" src={timeSlot123} />
          <span className="time-slot">{props.isModifyRide?timeSlot.selectNewSlot:timeSlot.selectSlot}</span>
        </div>
        <ul className="nav nav-tabs time-slot" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link ${selectedSlot === 1 ? "active" : ""}`}
              onClick={() => setSelectedSlot(1)}
            >
              <span className="timeslot-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12ZM5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM12,5a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5Zm5.66,2.34a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34Zm-12-.29a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5ZM12,19a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19Z" />
                </svg>
              </span>
              {timeSlot.morning}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${selectedSlot === 2 ? "active" : ""}`}
              onClick={() => setSelectedSlot(2)}
            >
              <span className="timeslot-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 135.467 135.467"
                >
                  <path
                    d="M17310.531 593.756a10 10 0 0 0-9.994 10.002v54.994a10 10 0 0 0 9.994 10.004 10 10 0 0 0 10.002-10.004v-54.994a10 10 0 0 0-10.002-10.002zm-120.31 32.2a10 10 0 0 0-4.864 1.343 10 10 0 0 0-3.662 13.656l27.506 47.635a10 10 0 0 0 13.656 3.662 10 10 0 0 0 3.661-13.664l-27.497-47.635a10 10 0 0 0-8.535-4.998 10 10 0 0 0-.265 0zm240.554 0a10 10 0 0 0-8.726 4.997l-27.496 47.635a10 10 0 0 0 3.654 13.664 10 10 0 0 0 13.662-3.662l27.498-47.635a10 10 0 0 0-3.654-13.656 10 10 0 0 0-4.938-1.344zm-119.293 68.458c-79.637-.507-145.416 61.444-150.615 139.998h-74.998a10 10 0 0 0-10.002 10.002 10 10 0 0 0 10.002 10.002h449.328a10 10 0 0 0 10.004-10.002 10 10 0 0 0-10.004-10.002h-75.008c-5.141-77.718-69.716-139.496-148.707-139.998zm-209.031 19.518a10 10 0 0 0-8.726 4.998 10 10 0 0 0 3.654 13.664l47.637 27.498a10 10 0 0 0 13.662-3.662 10 10 0 0 0-3.66-13.657l-47.637-27.505a10 10 0 0 0-4.93-1.336zm416.102 0a10 10 0 0 0-4.864 1.336l-47.636 27.505a10 10 0 0 0-3.66 13.657 10 10 0 0 0 13.662 3.662l47.629-27.498a10 10 0 0 0 3.662-13.664 10 10 0 0 0-8.528-4.998 10 10 0 0 0-.265 0zm-207.196.488c68.224.434 123.729 53.082 128.797 119.992h-259.232c5.135-67.63 61.653-120.43 130.435-119.992z"
                    color="#000"
                    fontFamily="sans-serif"
                    fontWeight="400"
                    overflow="visible"
                    transform="translate(-4928.709 -141.264) scale(.28864)"
                  ></path>
                </svg>
              </span>
              {timeSlot.afternoon}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${selectedSlot === 3 ? "active" : ""}`}
              onClick={() => setSelectedSlot(3)}
            >
              <span className="timeslot-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path d="M16 6a1 1 0 001-1V1a1 1 0 00-2 0v4a1 1 0 001 1zM16 26a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1zM6 16a1 1 0 00-1-1H1a1 1 0 000 2h4a1 1 0 001-1zM31 15h-4a1 1 0 000 2h4a1 1 0 000-2zM7.515 8.929a.999.999 0 101.414-1.414L6.101 4.686A.999.999 0 104.687 6.1l2.828 2.829zM24.485 23.071a.999.999 0 10-1.414 1.414l2.828 2.828a1 1 0 101.414-1.414l-2.828-2.828zM7.515 23.071l-2.828 2.828a.999.999 0 101.414 1.414l2.828-2.828a.999.999 0 10-1.414-1.414zM24.485 8.929L27.314 6.1A.999.999 0 1025.9 4.686l-2.828 2.828a.999.999 0 101.413 1.415zM16 7a9 9 0 00-9 9c0 4.971 4.029 9 9 9s9-4.029 9-9a9 9 0 00-9-9zm0 17V8a8 8 0 010 16z" />
                </svg>
              </span>
              {timeSlot.evening}
            </a>
          </li>
        </ul>

        {/* <!-- Tab panes --> */}
        <div className="tab-content">
          <div
            id="home"
            className={`tab-pane ${selectedSlot === 1 ? "active" : "fade"}`}
          >
            <div className="timing-sets">
              {availableSlots.morning ? (
                availableSlots.morning.map((item: any, ind: number) => {
                  const timeslots = item.timeSlot;
                  return (
                    <div className="form-check" key={item.id}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault3"
                        name="timeslot"
                        value="huey"
                        checked={
                          selectedSlot === 1 && selectedtimeID === timeslots.id
                        }
                        onChange={() => handletimechange(timeslots)}
                      />
                      <label className="form-check-labe smallest-text">{`${timeslots.from_time} - ${timeslots.to_time}`}</label>
                    </div>
                  );
                })
              ) : (
                <p>{timeSlot.NoSlot}</p>
              )}
            </div>
          </div>
          <div
            id="menu1"
            className={`tab-pane ${selectedSlot === 2 ? "active" : "fade"}`}
          >
            <div className="timing-sets">
              {availableSlots.afternoon ? (
                availableSlots.afternoon.map((item: any, ind: number) => {
                  const timeslots = item.timeSlot;
                  return (
                    <div className="form-check" key={item.id}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault3"
                        name="timeslot"
                        value="huey"
                        checked={
                          selectedSlot === 2 && selectedtimeID === timeslots.id
                        }
                        onChange={() => handletimechange(timeslots)}
                      />
                      <label className="form-check-labe smallest-text">{`${timeslots.from_time} - ${timeslots.to_time}`}</label>
                    </div>
                  );
                })
              ) : (
                <p>{timeSlot.NoSlot}</p>
              )}
            </div>
          </div>
          <div
            id="menu2"
            className={`tab-pane ${selectedSlot === 3 ? "active" : "fade"}`}
          >
            <div className="timing-sets">
              {availableSlots.evening ? (
                availableSlots.evening.map((item: any, ind: number) => {
                  const timeslots = item.timeSlot;
                  return (
                    <div className="form-check" key={item.id}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault3"
                        name="timeslot"
                        value="huey"
                        checked={
                          selectedSlot === 3 && selectedtimeID === timeslots.id
                        }
                        onChange={() => handletimechange(timeslots)}
                      />
                      <label className="form-check-labe smallest-text">{`${timeslots.from_time} - ${timeslots.to_time}`}</label>
                    </div>
                  );
                })
              ) : (
                <p>{timeSlot.NoSlot}</p>
              )}
            </div>
          </div>
        </div>
        {props.deliveryType && availableSlots.homeDeliveryTime && (
          <p className="applicable-info-deliveryTime">
            <span>*</span>
            {` After ${availableSlots.homeDeliveryTime} , Home Delivery is not applicable.`}
          </p>
        )}
      </div>
    </>
  );
};

export default SelectTimeSlot;
